<template>
  <!-- 覆盖waike样式 -->
  <waike title="注册" id="register">
    <template slot="form">
      <router-link to="/">返回登录</router-link>
      <div class="login-from">
        <el-form
          :model="ruleForm3"
          status-icon
          :rules="rules3"
          ref="ruleForm3"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              type="text"
              auto-complete="off"
              placeholder="请输入用户名"
              v-model="ruleForm3.username"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="password">
            <el-input
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              v-model="ruleForm3.password"
            ></el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="checkupwd">
            <el-input
              type="password"
              auto-complete="off"
              placeholder="请再次输入密码"
              v-model="ruleForm3.checkupwd"
            ></el-input>
          </el-form-item>

          <el-form-item label="手机号码" prop="uphone">
            <el-input
              type="text"
              auto-complete="off"
              placeholder="请输入手机号码"
              v-model="ruleForm3.uphone"
            ></el-input>
          </el-form-item>

          <el-form-item label="用户类型" prop="value">
            <el-select v-model="ruleForm3.value" style="width: 100%">
              <el-option label="请选择用户类型" value=""></el-option>
              <el-option
                v-for="item in ruleForm3.identity"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="真实姓名" prop="realname">
            <el-input
              type="email"
              auto-complete="off"
              placeholder="请输入真实姓名"
              v-model="ruleForm3.realname"
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="ruleForm3.value === '学生'"
            label="所属班级"
            prop="class"
          >
            <el-select
              v-model="ruleForm3.class"
              style="width: 100%"
              clearable
              filterable
            >
              <el-option label="请选择所属班级" value=""></el-option>
              <el-option
                v-for="item in ruleForm3.classes"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="邮箱" prop="uemail">
            <el-input
              type="email"
              auto-complete="off"
              placeholder="请输入邮箱"
              v-model="ruleForm3.uemail"
            ></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="verify">
            <el-input
              type="text"
              auto-complete="off"
              placeholder="请输入验证码"
              v-model="ruleForm3.verify"
              ><el-button :disabled="disabled" slot="append" @click="send"
                >发送验证码</el-button
              ></el-input
            >
          </el-form-item>

          <div class="button">
            <el-button type="primary" @click="submitForm('ruleForm3')"
              >注册</el-button
            >
            <el-button type="primary" @click="resetForm('ruleForm3')"
              >重置</el-button
            >
          </div>
        </el-form>
      </div>
    </template>
  </waike>
</template>

<script>
import waike from "./slot/waike.vue";
export default {
  components: { waike },
  data() {
    var validateName = (rule, value, callback) => {
      //发起请求验证用户名是否存在
      this.$http
        .post("/api/global/checkusername", `username=${value}`)
        .then((result) => {
          this.unameStatus = result.data.status;
          let reg = /[aA][vV]/; //敏感词
          if (value === "") {
            callback(new Error("请输入用户名"));
          } else if (this.unameStatus == 200) {
            callback(new Error("该用户名已存在"));
          } else if (/^\d+$/.test(value)) {
            callback(new Error("用户名不能为纯数字"));
          } else if (value.search(reg) != -1) {
            callback(new Error("有敏感词"));
          } else {
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 8 || /^\d+$/.test(value)) {
        callback(new Error("密码不能为纯数字,且不能小于8位"));
      } else {
        callback();
      }
    };

    var validateCheckupwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm3.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateUphone = (rule, value, callback) => {
      //发起请求验证输入的手机号是否存在
      this.$http
        .post("/api/global/checkphone", `phone=${value}`)
        .then((result) => {
          this.uphoneStatus = result.data.status;
          if (value === "") {
            callback(new Error("请输入手机号"));
          } else if (this.uphoneStatus == 200) {
            callback(new Error("该手机号已存在"));
          } else if (!/^1[3-9]\d{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号"));
          } else {
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    var validateType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择用户类型"));
      } else {
        callback();
      }
    };

    var validateRealname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入真实姓名"));
      } else {
        callback();
      }
    };

    var validateUemail = (rule, value, callback) => {
      //发起请求验证输入的邮箱是否存在
      this.$http
        .post("/api/global/checkemail", `email=${value}`)
        .then((result) => {
          this.uemailStatus = result.data.status;
          if (value === "") {
            this.disabled = true; //防止用户反复操作,如果不设置，用户本来输入正确了，此时按钮解除禁用，
            //然后突然删除又变错误了，此时要禁用按钮
            callback(new Error("请输入邮箱"));
          } else if (this.uemailStatus == 200) {
            this.disabled = true;
            callback(new Error("该邮箱已存在"));
          } else if (
            !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
          ) {
            this.disabled = true;
            callback(new Error("请输入正确的邮箱"));
          } else {
            this.disabled = false; //输入正确解除禁用
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    var validateYzm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value != this.ruleForm3.code) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    var validateClass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择班级"));
      } else {
        callback();
      }
    };
    return {
      unameStatus: "",
      uphoneStatus: "",
      uemailStatus: "",
      disabled: true,
      ruleForm3: {
        checkupwd: "",
        uphone: "",
        uemail: "",
        code: "",
        identity: ["教师", "学生"],
        realname: "",
        value: "",
        username: "",
        password: "",
        verify: "",
        class: "",
        classes: [],
      },
      rules3: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkupwd: [{ validator: validateCheckupwd, trigger: "blur" }],
        uphone: [{ validator: validateUphone, trigger: "blur" }],
        value: [{ validator: validateType, trigger: "change" }],
        realname: [{ validator: validateRealname, trigger: "blur" }],
        uemail: [{ validator: validateUemail, trigger: "blur" }],
        verify: [{ validator: validateYzm, trigger: "blur" }],
        class: [{ validator: validateClass, trigger: "change" }],
      },
    };
  },
  mounted() {
    this.getClass();
  },

  methods: {
    //获取班级
    getClass() {
      this.$http.get("/api/global/getmyclass").then((res) => {
        this.ruleForm3.classes = res.data.data;
      });
    },
    //注册
    submitForm(formName) {
      let obj = {};
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var registerUrl, user;
          if (this.ruleForm3.value == "教师") {
            registerUrl = "/api/teacher/register";
            user = "teacher";
            obj = {
              username: this.ruleForm3.username,
              password: this.ruleForm3.password,
              verify: this.ruleForm3.verify,
              phone: this.ruleForm3.uphone,
              email: this.ruleForm3.uemail,
              realName: this.ruleForm3.realname,
            };
          } else if (this.ruleForm3.value == "学生") {
            registerUrl = "/api/student/register";
            user = "student";
            obj = {
              username: this.ruleForm3.username,
              password: this.ruleForm3.password,
              verify: this.ruleForm3.verify,
              phone: this.ruleForm3.uphone,
              email: this.ruleForm3.uemail,
              realName: this.ruleForm3.realname,
              className: this.ruleForm3.class,
            };
          }

          this.$http.post(registerUrl, obj).then(
            (res) => {
              // console.log(res.data)
              if (res.data.status == 1) {
                this.$message({ type: "success", message: "注册成功" });
                this.$router.push("/login");
              } else {
                this.$message({ type: "error", message: res.data.msg });
              }
            },
            (err) => {
              console.log(err);
              this.$message({ type: "error", message: "注册失败" });
            }
          );
        } else {
          return false;
        }
      });
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //发送验证码axios
    sendaxios() {
      this.$http
        .get("/api/global/email", {
          params: {
            email: this.ruleForm3.uemail,
          },
        })
        .then((res) => {
          this.ruleForm3.code = res.data.yzm;
          //   console.log(res.data.yzm);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //点击发送验证码
    send(e) {
      this.sendaxios();
      let time = 60; //定义60秒的倒计时
      let that = this; //因为this在函数中指向window，所以定义that接收
      function subs() {
        time--;
        e.target.innerHTML = `请${time}秒后再试`;
        if (time === 0) {
          clearInterval(mytime);
          e.target.innerHTML = `发送验证码`;
          that.disabled = false; //按键可用
        } else {
          that.disabled = true; //按键不可用
        }
      }
      //设置一个定时，一秒执行一次
      let mytime = setInterval(function () {
        subs();
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
#register {
  .login-content {
    width: 400px;
    height: 720px;
    overflow: auto;
    margin: auto;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    font-size: 12px;
    transform: translateY(-50%);
  }
  .button {
    margin-bottom: 15px;
  }
}
</style>
